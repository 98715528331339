<template>
    <ValidationProvider
        ref="validationProvider"
        v-slot="{ errors, valid }"
        rules="minStringLength">
        <LTooltip
            :active="!valid"
            :label="errors[0]"
            class="is-block"
            position="is-bottom"
            type="is-danger">
            <LInput
                v-model="localValue"
                :placeholder="placeholder"
                expanded>
            </LInput>
        </LTooltip>
    </ValidationProvider>
</template>

<script>
  import { extend, ValidationProvider } from "vee-validate";
  
  export default {
    name: "InputWithValidation",
    
    components: {
      ValidationProvider
    },
    
    props: {
      rules: {
        type: Array,
        default: () => []
      },

      value: {
        type: String,
        default: null
      },

      placeholder: {
        type: String,
        default: null
      }
    },

    mounted () {
      this.rules.forEach(({ name, handler }) => {
        extend(name, handler);
      });
    },

    data () {
      return {
        localValue: this.value
      };
    },

    watch: {
      async localValue (value) {
        if (value) {
          const { valid } = await this.$refs.validationProvider.validate();

          if (valid) {
            return this.$emit("input", value);
          }
        }

        return this.$emit("input", null);
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
